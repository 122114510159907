import { BalHeading, BalText } from "@baloise/ds-react";
import {
    DeepPartial,
    Form,
    FormProps,
    FormValues,
    Language,
} from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { DealerLayout, driverFormConfig, TabsLayout } from "@modules/dealer";
import { CompletedCard, Navigation } from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import {
    findNextInvalidTab,
    getAddress,
    getNavigationPages,
    validateBusinessRules,
} from "@modules/shared/helpers";
import { useWtc } from "@modules/shared/hooks";
import { appState } from "@modules/shared/state";
import { PageProps, TabsStatus } from "@modules/shared/types";

const DealerDriverPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState(appState);
    const { getWtcPage, businessRules } = useWtc(allPages);
    const { t } = useTranslation();
    const innerLanguage: Language =
        language === "nl" ? Language.NL : Language.FR;

    const { prevPage, nextPage } = getNavigationPages(allPages, page);

    const [formProps, setFormProps] = useState<
        Pick<FormProps<FormValues>, "dirty" | "isValid">
    >({
        isValid: false,
        dirty: false,
    });

    const initialFormValues: DeepPartial<FormValues> = useMemo(() => {
        return {
            ...appData,
            DealerDriver: {
                ...appData?.DealerDriver,
                address: getAddress(appData?.DealerDriver, innerLanguage),
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateFormState = (formValues: FormValues): void => {
        const { DealerDriver } = formValues;

        setAppData({
            ...appData,
            DealerDriver: {
                ...DealerDriver,
                city: {
                    value: DealerDriver?.address?.city?.data,
                },
                street: {
                    value: {
                        id: DealerDriver?.address?.street?.value,
                        name: DealerDriver?.address?.street?.title,
                    },
                },
                bus: DealerDriver?.address?.bus,
                houseNumber: DealerDriver?.address?.houseNumber,
            },
        });
    };

    const handleSubmit = (values: FormValues): void => {
        updateFormState(values);

        const wtcPage = getWtcPage(
            validateBusinessRules(businessRules, appData).action,
        );

        if (wtcPage) {
            navigate(wtcPage.paths[language]);
            return;
        }
        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (
        values: FormValues,
        props: Pick<FormProps<FormValues>, "dirty" | "isValid">,
    ): void => {
        setFormProps(props);
        updateFormState(values);
    };

    const renderHeaderComponent = useCallback(
        (tabsStatus: TabsStatus) => {
            const showCompleteCard = formProps.dirty
                ? formProps.isValid
                : tabsStatus && tabsStatus[page.name]?.isValid;
            const nextInvalidTab = findNextInvalidTab(
                tabsStatus,
                page,
                allPages,
                language,
            );
            if (showCompleteCard) {
                return (
                    <CompletedCard
                        nextPage={nextInvalidTab ?? nextPage}
                        currentPage={page}
                        language={language}
                    />
                );
            }
        },
        [
            formProps.dirty,
            formProps.isValid,
            page,
            nextPage,
            language,
            allPages,
        ],
    );
    return (
        <DealerLayout
            title={t("dealer.dealerdriver.title")}
            page={page}
            language={language}
            allPages={allPages}
        >
            <TabsLayout
                activeTabName={page.name}
                title={t("dealer.tabs.title")}
                description={t("dealer.tabs.description")}
                language={language}
                allPages={allPages}
            >
                {({ tabsStatus }) => {
                    return (
                        <>
                            <div className="intro">
                                <BalHeading>
                                    {t("dealer.dealerdriver.title")}
                                </BalHeading>
                                <BalText>
                                    {t("dealer.dealerdriver.intro")}
                                </BalText>
                            </div>
                            <Form
                                scrollToFieldError
                                formContext={{
                                    t,
                                    language: innerLanguage,
                                    nameSpace: PageNames.DealerDriver,
                                    fieldWrapper: {
                                        optionalLabel: "all.optional",
                                    },
                                }}
                                initialValues={initialFormValues}
                                onSubmit={handleSubmit}
                                onChange={handleOnChange}
                                fields={driverFormConfig.fields}
                                errorMessageCardTitle={
                                    t("all.errormessage.title") as string
                                }
                                errorMessageCardSubTitle={
                                    t("all.errormessage.text") as string
                                }
                                headerComponent={() =>
                                    renderHeaderComponent(tabsStatus)
                                }
                            >
                                <Navigation
                                    t={t}
                                    language={language}
                                    prevPage={prevPage}
                                    nextPage={nextPage}
                                    nextColor="info"
                                />
                            </Form>
                        </>
                    );
                }}
            </TabsLayout>
        </DealerLayout>
    );
};

export default DealerDriverPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
